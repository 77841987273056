import cx from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import styles from './part-button.module.scss';

interface PartButtonProps {
  option: string;
  text: string;
  slug?: string;
  mobileMenuOpened?: boolean;
}

const PartButton: FC<PartButtonProps> = ({
  option, text, slug, mobileMenuOpened,
}) => {
  let classes: string | string[];

  switch (option) {
    case 'header':
      classes = styles.contactBtn;
      break;
    case 'body-color':
      classes = [styles.contactBtn, styles.contactBtnBodyColor];
      break;
    case 'transparent':
      classes = [styles.contactBtn, styles.contactBtnBodyTransparent];
      break;
    case 'body-dark':
      classes = [styles.contactBtn, styles.contactBtnBodyDark];
      break;
    default:
      classes = styles.contactBtn;
  }

  return (
    <Link
      className={cx(classes, {
        [styles.mobileMenuOpened]: mobileMenuOpened,
      })}
      href={slug || '/#'}
      tabIndex={0}
    >
      <span>{text}</span>
    </Link>
  );
};

export default PartButton;
