'use client';

/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  FC,
  ReactNode,
} from 'react';

export type ILanguage = 'lv' | 'en' | 'ru';
interface ILanguageContextType {
  language: ILanguage;
  setLanguage: (language: ILanguage) => void;
}

const LanguageContext = createContext<ILanguageContextType | undefined>(
  undefined,
);

export const LanguageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<ILanguage>('lv');

  const value = useMemo(() => ({ language, setLanguage }), [language]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
