import React, { FC, ReactNode } from 'react';

import styles from './part-design-elements-container.module.scss';

interface PartDesignElementsContainerProps {
  children: ReactNode;
}

const PartDesignElementsContainer: FC<PartDesignElementsContainerProps> = ({ children }) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default PartDesignElementsContainer;
