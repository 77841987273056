const data = [
  {
    title: 'Latviski',
    link: '/#',
    short: 'Lv',
    language: 'lv',
  },
  {
    title: 'English',
    link: '/#',
    short: 'En',
    language: 'en',
  },
  {
    title: 'Русский',
    link: '/#',
    short: 'Ru',
    language: 'ru',
  },
];

export default data;
