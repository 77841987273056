'use client';

import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import PartButton from '../UI/part-button/part-button';
import { IDetailes } from '@/contentful/utils/detailes';
import { IListItem } from '@/contentful/utils/listItem';
import { IMainNavigationLinks } from '@/contentful/utils/mainNavigationLinks';
import { useLanguage } from '@/context/LanguageContext';

import IconBank from './assets/icon-bank.svg';
import IconClock from './assets/icon-clock.svg';
import IconEmail from './assets/icon-email.svg';
import IconFacebook from './assets/icon-facebook.svg';
import IconInstagram from './assets/icon-instagram.svg';
import IconPhone from './assets/icon-phone.svg';
import IconPin from './assets/icon-pin.svg';
import LogoFooter from './assets/logo-vissa-footer.png';

import styles from './part-footer.module.scss';

interface IPartFooterProps {
  data: {
    mainNavigationLinks: IMainNavigationLinks[];
    detailes: IDetailes[];
    social: IListItem[];
  };
}

const PartFooter: FC<IPartFooterProps> = ({ data }) => {
  const { language } = useLanguage();

  const footerLinks = data.mainNavigationLinks.filter(
    (item) => item.lang === language,
  )[0].navigationList;
  const footerDetailes = data.detailes.filter(
    (item) => item.lang === language,
  )[0];
  const footerSocial = data.social;

  const {
    lang,
    workingHoursTitle,
    workingHoursFull,
    phoneTitle,
    phones,
    emailTitle,
    emails,
    addressTitle,
    addressFull,
    bankingDetailsTitle,
    officialCompanyName,
    companyCod,
    bankName,
    account,
    button,
  } = footerDetailes;

  const companyPhoneLink = phones?.match(/\+?\d+/g)?.join('');

  let copyrightYearsText = '2023';
  const yearNow = new Date().getFullYear();
  if (yearNow > parseInt(copyrightYearsText, 10)) {
    copyrightYearsText += `-${String(yearNow)}`;
  }
  return (
    <footer className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.logoContentWrap}>
          <div className={styles.LogoWrap}>
            <Link href={`/${lang}`}>
              <Image
                src={LogoFooter}
                width={146}
                height={90}
                alt="logo Vissa footer"
              />
            </Link>
          </div>
          <ul className={styles.socialsWrap}>
            {footerSocial.map(({ title, slug }) => (
              <li key={`mobile-social-menu-${title} + ${slug}`}>
                <a href={slug} target="_blank" rel="nofollow noreferrer">
                  <div className={styles.socialIcon}>
                    {title === 'instagram' && <IconInstagram />}
                    {title === 'facebook' && <IconFacebook />}
                  </div>
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.copyright}>
            <p>{`Copyright © ${copyrightYearsText}`}</p>
            <p>Vissa Autoserviss.</p>
            <p>All Rights Reserved</p>
          </div>
        </div>
        <div className={styles.contactsWrap}>
          <div className={styles.contactItem}>
            <div className={styles.contactItemTitleWrap}>
              <IconClock />
              <h3 className={styles.contactItemTitle}>{workingHoursTitle}</h3>
            </div>
            <p className={styles.contactItemText}>{workingHoursFull[0]}</p>
            <p className={styles.contactItemText}>{workingHoursFull[1]}</p>
          </div>

          <div className={styles.contactItem}>
            <div className={styles.contactItemTitleWrap}>
              <IconPhone />
              <h3 className={styles.contactItemTitle}>{phoneTitle}</h3>
            </div>
            <div className={styles.contactLinkWrap}>
              <a
                href={`tel:${companyPhoneLink}`}
                rel="nofollow noreferrer"
                target="_blank"
                className={styles.contactLink}
              >
                <span className={styles.contactLinkText}>{phones}</span>
              </a>
            </div>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactItemTitleWrap}>
              <IconEmail />
              <h3 className={styles.contactItemTitle}>{emailTitle}</h3>
            </div>
            <a
              href={`mailto:${emails}`}
              rel="nofollow noreferrer"
              target="_blank"
              className={styles.contactLink}
            >
              <span className={styles.contactLinkText}>{emails}</span>
            </a>
          </div>
        </div>
        <div className={styles.contactsWrap}>
          <div className={styles.contactItem}>
            <div className={styles.contactItemTitleWrap}>
              <IconPin />
              <h3 className={styles.contactItemTitle}>{addressTitle}</h3>
            </div>
            <p className={styles.contactItemText}>{addressFull[0]}</p>
            <p className={styles.contactItemText}>{addressFull[1]}</p>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactItemTitleWrap}>
              <IconBank />
              <h3 className={styles.contactItemTitle}>{bankingDetailsTitle}</h3>
            </div>
            <div className={cx(styles.contactItemText, styles.bankText)}>
              <p>{officialCompanyName}</p>
              <p>{companyCod}</p>
            </div>
            <div className={cx(styles.contactItemText, styles.bankText)}>
              <p>{bankName}</p>
              <p>{account}</p>
            </div>
          </div>
        </div>
        <div className={styles.menuWrap}>
          <ul className={styles.menuList}>
            {Array.isArray(footerLinks)
              ? footerLinks.map(({ title, link }) => (
                <li key={`footer-menu-${title} + ${link}`}>
                  <Link
                    href={link}
                    className={cx(styles.menuLink, styles.contactLinkText)}
                  >
                    {title}
                  </Link>
                </li>
              ))
              : null}
          </ul>
          <PartButton
            option="header"
            text={button ?? ''}
            slug={
              language === 'lv'
                ? '/contact/#order'
                : `/${language}/contact/#order`
            }
          />
        </div>
      </div>
    </footer>
  );
};

export default PartFooter;
