import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { designElementsParentContainerClass } from '../../part-design-elements-container';
import PartButton from '@/components/UI/part-button/part-button';
import { IDetailes } from '@/contentful/utils/detailes';
import { IListItem } from '@/contentful/utils/listItem';

import IconCall from './assets/call.svg';
import IconFacebook from './assets/facebook.svg';
import IconInstagram from './assets/instagram.svg';
import LogoMobileMenu from './assets/logo-vissa-mobile.png';
import IconPin from './assets/pin.svg';
import IconWatch from './assets/watch.svg';

import styles from './part-mobile-menu.module.scss';

interface PartMobileMenuProps {
  id: string;
  className: string;
  links: {
    link: string;
    title: string;
    active: boolean;
  }[];
  detailes: IDetailes;
  social: IListItem[];
  language: string;
  toggler: () => void;
}

const PartMobileMenu: FC<PartMobileMenuProps> = ({
  id,
  className,
  links,
  detailes,
  social,
  language,
  toggler,
}) => {
  const {
    phones, addressShort, workingHoursShort, button,
  } = detailes;
  const companyPhoneLink = phones?.match(/\+?\d+/g)?.join('');
  return (
    <div
      className={cx(
        styles.container,
        className,
        designElementsParentContainerClass,
      )}
      id={id}
    >
      <div className={styles.content}>
        <Link className={styles.logoMobile} href="/" onClick={toggler}>
          <Image
            src={LogoMobileMenu}
            width={147}
            height={90}
            alt="logo Vissa mobile menu"
          />
        </Link>
        <div className={styles.mobileMenuContent}>
          <div className={styles.menuContainer}>
            {links.map(({ title, link }) => (
              <Link
                key={`mobile-menu${title} + ${link}`}
                href={link}
                className={styles.menuLink}
                onClick={toggler}
              >
                {title}
              </Link>
            ))}
          </div>
          <PartButton
            option="header"
            text={button ?? ''}
            slug={
              language === 'lv'
                ? '/contact/#order'
                : `/${language}/contact/#order`
            }
          />
        </div>
        <div className={styles.mobileMenuFooter}>
          <div className={styles.mobileMenuFooterItem}>
            <IconCall />
            <a
              href={`tel:${companyPhoneLink}`}
              rel="nofollow noreferrer"
              target="_blank"
              className={styles.mobileMenuFooterLink}
            >
              <span className={styles.mobileMenuFooterText}>{phones}</span>
            </a>
          </div>

          <div className={styles.mobileMenuFooterItem}>
            <IconPin />
            <p>{addressShort}</p>
          </div>
          <div className={styles.mobileMenuFooterBottom}>
            <div className={styles.mobileMenuFooterItem}>
              <IconWatch />
              <p>{workingHoursShort}</p>
            </div>
            <ul className={styles.mobileMenuFooterSocialsWrap}>
              {social.map(({ slug, title }) => (
                <li key={`mobile-social-menu-${slug} + ${title}`}>
                  <a href={slug} target="_blank" rel="nofollow noreferrer">
                    <div className={styles.menuContainerSocialIcon}>
                      {title === 'instagram' && <IconInstagram />}
                      {title === 'facebook' && <IconFacebook />}
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartMobileMenu;
