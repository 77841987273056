'use client';

import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, {
  useState, useEffect, useRef, FC,
} from 'react';
import unwrapElement from 'unwrap-element';

import PartButton from '../UI/part-button/part-button';
import { IDetailes } from '@/contentful/utils/detailes';
import { IListItem } from '@/contentful/utils/listItem';
import { IMainNavigationLinks } from '@/contentful/utils/mainNavigationLinks';
import { useLanguage } from '@/context/LanguageContext';

import IconCall from './assets/call.svg';
import IconFacebook from './assets/facebook.svg';
import IconInstagram from './assets/instagram.svg';
import LogoMain from './assets/logo-vissa-main.png';
import LogoMobile from './assets/logo-vissa-mobile-light.png';
import IconPin from './assets/pin.svg';
import IconWatch from './assets/watch.svg';
import PartLangMenu from './part-lang-menu/part-lang-menu';
import PartMobileMenu from './part-mobile-menu';

import styles from './part-header.module.scss';

type UnwrapCleanup = () => void;

interface IPartHeaderProps {
  data: {
    mainNavigationLinks: IMainNavigationLinks[];
    detailes: IDetailes[];
    social: IListItem[];
  };
}

const PartHeader: FC<IPartHeaderProps> = ({ data }) => {
  const { language } = useLanguage();

  const headerLinks = data.mainNavigationLinks.filter(
    (item) => item.lang === language,
  )[0].navigationList;
  const headerDetailes = data.detailes.filter(
    (item) => item.lang === language,
  )[0];
  const headerSocial = data.social;

  const {
    phones, addressShort, workingHoursShort, button,
  } = headerDetailes;

  const pathname = usePathname();
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const mobileMenuToggler = () => {
    setMobileMenuOpened((prev) => !prev);
  };

  const links = Array.isArray(headerLinks)
    ? headerLinks.map((route) => ({
      ...route,
      active: pathname === route.link,
    }))
    : [];

  const companyPhoneLink = phones?.match(/\+?\d+/g)?.join('');

  const destroyUnwrapReference = useRef<UnwrapCleanup | null>(null);

  useEffect(() => {
    if (mobileMenuOpened) {
      destroyUnwrapReference.current = unwrapElement('#mobileMenu', {
        bypassSelectorsOrNodes: ['body'],
      });
    } else {
      destroyUnwrapReference.current?.();
    }
    return () => {
      // код отписки от ресурса
      destroyUnwrapReference.current?.();
    };
  }, [mobileMenuOpened]);

  return (
    <>
      <header
        className={cx(styles.headerBox, {
          [styles.mobileMenuOpened]: mobileMenuOpened,
        })}
        id="header"
      >
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <Link className={styles.logo} href="/">
              <Image
                src={LogoMain}
                width={162}
                height={80}
                alt="VISSA logo main"
              />
            </Link>
          </div>
          <div className={styles.menuContainer}>
            <div className={styles.menuContainerTop}>
              <div className={styles.menuContainerTopItem}>
                <IconCall />
                <a
                  href={`tel:${companyPhoneLink}`}
                  rel="nofollow noreferrer"
                  target="_blank"
                  className={styles.menuContainerTopLink}
                >
                  <span className={styles.menuContainerTopText}>{phones}</span>
                </a>
              </div>

              <div className={styles.menuContainerTopItem}>
                <IconPin />
                <p>{addressShort}</p>
              </div>
              <div className={styles.menuContainerTopItem}>
                <IconWatch />
                <p>{workingHoursShort}</p>
              </div>
              <ul className={styles.menuContainerTopSocialsWrap}>
                {headerSocial.map(({ slug, title }) => (
                  <li key={`mobile-social-menu-${title} + ${slug}`}>
                    <a href={slug} target="_blank" rel="nofollow noreferrer">
                      <div className={styles.menuContainerSocialIcon}>
                        {title === 'instagram' && <IconInstagram />}
                        {title === 'facebook' && <IconFacebook />}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <nav className={styles.menuContainerBottom}>
              <ul className={styles.menuContainerBottomNav}>
                {links.map(({ title, link, active }) => (
                  <li
                    key={`desktop-menu${title} + ${link}`}
                    className={styles.menuContainerBottomNavItem}
                  >
                    <Link
                      href={link}
                      className={cx(styles.menuContainerBottomLink, {
                        [styles.menuLinkActive]: active,
                      })}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
              <PartButton
                option="header"
                text={button ?? ''}
                slug={language === 'lv' ? '/contact/#order' : `/${language}/contact/#order`}
              />
              <PartLangMenu device="desktop" />
            </nav>
          </div>
          <div className={styles.menuContainerMobile}>
            <PartButton
              mobileMenuOpened={mobileMenuOpened}
              option="header"
              text={button ?? ''}
              slug={language === 'lv' ? '/contact/#order' : `/${language}/contact/#order`}
            />
            <Link
              className={cx(styles.logoMobile, {
                [styles.mobileMenu]: mobileMenuOpened,
              })}
              href="/"
            >
              <Image
                src={LogoMobile}
                width={85}
                height={40}
                alt="VISSA logo mobile"
              />
            </Link>
            <div className={cx({ [styles.mobileMenu]: !mobileMenuOpened })}>
              <PartLangMenu device="mobile" />
            </div>
            <button
              type="button"
              className={styles.burgerButton}
              data-opened={mobileMenuOpened ? '' : undefined}
              onClick={mobileMenuToggler}
            >
              <div className={styles.burgerIcon}>
                <i />
                <i />
                <i />
              </div>
            </button>
          </div>
        </div>
      </header>
      <PartMobileMenu
        id="mobileMenu"
        className={styles.mobileMenu}
        links={links}
        social={headerSocial}
        detailes={headerDetailes}
        toggler={mobileMenuToggler}
        language={language}
      />
    </>
  );
};

export default PartHeader;
