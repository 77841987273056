/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

'use client';

import cx from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import React, {
  FC, useState, useCallback, KeyboardEvent,
} from 'react';

import { useLanguage, ILanguage } from '@/context/LanguageContext';

import LanguageIcon from './assets/language.svg';
import Shevron from './assets/shevron.svg';

import styles from './part-lang-menu.module.scss';

import data from './data';

interface LanguageOption {
  title: string;
  short: string;
  link: string;
  language: string;
}

interface PartLangMenuProps {
  device: string;
}

const PartLangMenu: FC<PartLangMenuProps> = ({ device }) => {
  const { language, setLanguage } = useLanguage();
  const router = useRouter();
  const pathname = usePathname();

  const [current, setCurrent] = useState(language);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  const extractRouteFromURL = (url: string) => url
    .split('/')
    .filter((item) => !['ru', 'en'].includes(item))
    .splice(1, 1)
    .join('/');

  const handleLanguageInteraction = useCallback(
    (chosenLang: string): void => {
      setCurrent(chosenLang as ILanguage);
      setLanguage(chosenLang as ILanguage);
      toggleMenu();
      const asPath = extractRouteFromURL(pathname);
      const newPath = chosenLang === 'lv' ? `/${asPath}` : `/${chosenLang}/${asPath}`;
      router.push(newPath);
    },
    [setLanguage, toggleMenu, pathname],
  );

  const handleCurrentOptionView = (langOpt: string) => {
    const currentLang = data.find((lang) => lang.language === langOpt);
    return currentLang ? currentLang.short : '';
  };

  const handleMenuKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter') {
        toggleMenu();
      }
    },
    [toggleMenu],
  );

  const handleOptionKeyDown = useCallback(
    (event: KeyboardEvent<HTMLLIElement>, chosenLang: string): void => {
      if (event.key === 'Enter') {
        handleLanguageInteraction(chosenLang);
      }
    },
    [handleLanguageInteraction],
  );

  return (
    <div
      className={
        device === 'desktop' ? styles.containerDesktop : styles.containerMobile
      }
    >
      <div className={styles.wrap}>
        <LanguageIcon className={styles.langicon} />
        {device === 'desktop' && (
          <div
            className={styles.currentContainer}
            onClick={toggleMenu}
            onKeyDown={handleMenuKeyDown}
            role="button"
            tabIndex={0}
          >
            <p className={styles.currentLang}>
              {handleCurrentOptionView(current)}
            </p>
            <div className={styles.shevronContainer}>
              <Shevron
                className={cx(styles.shevron, {
                  [styles.shevronUp]: isMenuOpen,
                })}
              />
            </div>
          </div>
        )}
      </div>
      {(isMenuOpen || device === 'mobile') && (
        <ul
          className={
            device === 'desktop'
              ? styles.optionsContainerDesktop
              : styles.optionsContainerMobile
          }
        >
          {data.map((lang: LanguageOption) => (
            <li
              key={lang.title}
              className={cx(
                styles.optionsLink,
                {
                  [styles.optionsLinkMonile]: device === 'mobile',
                },
                {
                  [styles.optionsLinkActive]: lang.short === current,
                },
              )}
              onClick={() => handleLanguageInteraction(lang.language)}
              onKeyDown={(event) => handleOptionKeyDown(event, lang.language)}
              role="button"
              tabIndex={0}
            >
              <p>{lang.short}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PartLangMenu;
